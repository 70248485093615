import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ROOT_URL_BACKEND } from 'src/api/constants';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const RequestStatusStats = () => {
    const [stats, setStats] = useState([null]);

    const [labels, setLabels] = useState([]);
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Request Status Count',
            },
        },
    };

    useEffect(() => {
        async function getStats() {
            const token = localStorage.getItem('token');

            const options = {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            };

            const response = await fetch(`${ROOT_URL_BACKEND}/backoffice/stats/status`, options);
            const data = await response.json();

            const labels = [];

            data.data.forEach((element) => {
                labels.push(element.status);
            });

            setLabels(labels.sort());

            const stats = [];

            labels.forEach((l) => {
                data.data.forEach((d) => {
                    if (d.status === l) {
                        stats.push(d.count);
                    }
                });
            });

            setStats(stats);
        }

        getStats();
    }, []);

    const data2 = {
        labels,
        datasets: [
            {
                label: 'Requests',
                data: stats,
                backgroundColor: [
                    'rgba(40, 30, 255,0.6)',
                    'rgba(43, 156, 87, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(166, 124, 0, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    'rgba(253, 1, 0, 0.6)',
                ],
            },
        ],
    };
    return <Bar options={options} data={data2} />;
};
export default RequestStatusStats;
