import { Col, Row, Divider } from 'antd';
import React from 'react';
import MessageStats from 'src/components/chats/MessageStats';
import RequestStats from 'src/components/chats/ReqeustStats';
import RequestEndpointStats from 'src/components/chats/RequestEndpointStats';

import RequestStatusStats from 'src/components/chats/RequestStatusStats';
import { MainLayout } from 'src/components/composite';
import Stats from 'src/components/ui/Stats';
import UserList from 'src/components/ui/UserList';

const Dashboard = () => {
    return (
        <MainLayout>
            <div
                className="site-layout-background"
                style={{
                    backgroundColor: 'white',
                    padding: 32,
                }}
            >
                <Row gutter={16}>
                    <Stats />
                </Row>
                <Divider />
                <div style={{ height: '100px' }}></div>
                <Row>
                    <Col style={{ height: '500px' }} span={12}>
                        <h1>Request Status Stats</h1>
                        <RequestStatusStats />
                    </Col>
                    <Col style={{ height: '500px' }} span={12}>
                        <h1>Request Stats</h1>
                        <RequestStats />
                    </Col>
                </Row>
                <br />
                <br />
                <br />
                <Row>
                    <Col style={{ height: '500px' }} span={12}>
                        <h1>Request Endpoint Stats</h1>
                        <RequestEndpointStats />
                    </Col>
                    <Col style={{ height: '500px' }} span={12}>
                        <h1>Daily Messages Count</h1>
                        <MessageStats />
                    </Col>
                </Row>
                <br />
                <br />
                <br />
                <Row>
                    <Col style={{ height: '500px' }} span={12}>
                        <h1>New Users</h1>
                        <UserList />
                    </Col>
                </Row>
            </div>
        </MainLayout>
    );
};

export default Dashboard;
