import moment from 'moment';
import { Avatar, List,Badge } from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROOT_URL_BACKEND } from 'src/api/constants';

const UserList = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        async function getData() {
            const token = localStorage.getItem('token');

            const options = {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            };

            const response = await fetch(`${ROOT_URL_BACKEND}/backoffice/profiles/newest?size=15`, options);
            const data = await response.json();

            setUsers(data.data);
        }

        getData();
    }, []);

    return (
        <List
            style={{
                height: 400,
                overflow: 'auto',
                padding: '0 16px',
                border: '1px solid rgba(140, 140, 140, 0.35)',
            }}
            itemLayout="horizontal"
            dataSource={users}
            renderItem={(item, index) => (
                <List.Item>
                    <List.Item.Meta
                        avatar={<Avatar src={item.profileImage} />}
                        title={<a onClick={() => navigate(`/users/${item._id}`)}>{item.username}</a>}
                        description={<Badge status={item.status === 'online' ? 'success' : 'default'} text={item.status} />}
                    />
                    <div>Last Activiy: {item.lastSeen ? moment(new Date(item.lastSeen), 'YYYYMMDD').fromNow() : 'No Activity'}</div>
                </List.Item>
            )}
        />
    );
};
export default UserList;
