import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ROOT_URL_BACKEND } from 'src/api/constants';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const RequestStats = () => {
    const [stats, setStats] = useState([null]);

    const [labels, setLabels] = useState([]);
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Daily Requests Count',
            },
        },
    };

    useEffect(() => {
        async function getStats() {
            const token = localStorage.getItem('token');

            const options = {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            };

            const response = await fetch(`${ROOT_URL_BACKEND}/backoffice/stats/requests`, options);
            const data = await response.json();

            const labels = [];

            data.data.forEach((element) => {
                labels.push(element.date);
            });

            setLabels(labels.sort());

            const stats = [];

            labels.forEach((l) => {
                data.data.forEach((d) => {
                    if (d.date === l) {
                        stats.push(d.count);
                    }
                });
            });

            setStats(stats);
        }

        getStats();
    }, []);

    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'Requests',
                data: stats,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };
    return <Line options={options} data={data} />;
};
export default RequestStats;
