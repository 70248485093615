import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { ROOT_URL_BACKEND } from '../api/constants';

const Login = () => {
    const [messageApi, contextHolder] = message.useMessage();

    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        const body = {
            email: values.username,
            password: values.password,
        };

        const options = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(body),
        };

        const response = await fetch(`${ROOT_URL_BACKEND}/backoffice/login`, options);

        const res = await response.json();

        if (response.status == 201) {
            const token = res.data.token;
            localStorage.setItem('token', token);
            setLoading(false);

            window.location.href = '/';
        } else {
            setLoading(false);

            messageApi.open({
                type: 'error',
                content: res.message,
            });
        }
    };

    return (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
            {contextHolder}
            <Form name="basic" onFinish={onFinish} autoComplete="off">
                <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Login
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};
export default Login;
