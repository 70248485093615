import React, { useState, useEffect } from 'react';
import { Input, Table, Select, Typography, Avatar, Space, Tag } from 'antd';

import { MainLayout } from 'src/components/composite';
import { ROOT_URL_BACKEND } from 'src/api/constants';
import { useNavigate } from 'react-router-dom';

const { Search } = Input;
const { Option } = Select;
const { Text } = Typography;

const UserList = () => {
    const navigate = useNavigate();

    const [limit, setLimit] = useState(50);
    const [allData, setAllData] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('id');
    const [name, setName] = useState(undefined);
    const [value, setValue] = useState(undefined);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [isDeleted, setIsDeleted] = useState(false);
    const [queriedPage, setQueriedPage] = useState(1);
    const [tableLoading, setTableLoading] = useState(true);
    const [pagination, setPagination] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const columns = [
        {
            title: 'ID',
            dataIndex: '_id',
            render: (id) => <a onClick={() => navigate(`/users/${id}`)}>{id}</a>,
        },
        {
            title: 'Username',
            render: (profile) => (
                <div className="space-align-block">
                    <Space align="center">
                        <Avatar src={profile.profileImage}>{profile.username[0].toUpperCase() + profile.username[1].toUpperCase()}</Avatar>
                        <span className="mock-block">{profile.username}</span>
                    </Space>
                </div>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status) => {
                var color = 'red';

                if (status === 'online') {
                    color = 'green';
                }

                return <Tag color={color}>{status}</Tag>;
            },
        },
        {
            title: 'Connected Sockets',
            dataIndex: 'connectedSockets',
        },
    ];

    useEffect(() => {
        async function getData() {
            const token = localStorage.getItem('token');
            const options = {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            };

            const response = await fetch(`${ROOT_URL_BACKEND}/backoffice/profiles?limit=${limit}&isDeleted=${isDeleted}`, options);

            const response_data = await response.json();

            const data = [];

            response_data.data.forEach((element) => {
                data.push({ key: element._id, ...element });
            });

            setData(data);
            setSelectedFilter('id');
            setAllData(data);
            setCount(response_data.count);
            setTableLoading(false);
            setPagination({
                current: 1,
                pageSize: limit,
                total: response_data.count,
                position: ['bottomCenter'],
            });
        }

        getData();
    }, []);

    async function handleTableChange(pagination) {
        try {
            var filterQuery = '';

            if ((name && value) || name === 'scount') {
                filterQuery = `name=${name}&value=${value}`;
            }

            if (pagination.current > queriedPage || queriedPage === 1) {
                const diff = Math.abs(pagination.current - queriedPage);

                let lmt = limit;
                if (diff !== 1) {
                    lmt = diff * limit;
                }

                setTableLoading(true);

                const lastItem = data[data.length - 1];

                const token = localStorage.getItem('token');

                const options = {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                };

                const response = await fetch(`${ROOT_URL_BACKEND}/backoffice/profiles?limit=${lmt}&isDeleted=${isDeleted}&paginationId=` + lastItem._id + `&${filterQuery}`, options);

                const response_data = await response.json();

                const responseData = [];

                response_data.data.forEach((element) => {
                    responseData.push({ key: element._id, ...element });
                });

                const slicesArray = responseData.slice(-limit);
                const newAllData = [];
                newAllData.push(...allData, ...responseData);

                setAllData(newAllData);
                setData(slicesArray);
                setTableLoading(false);
                setQueriedPage(pagination.current);
                setPagination(pagination);
                setPage(pagination.current);
            } else {
                const a = allData.slice((pagination.current - 1) * limit, pagination.current * limit);

                setData(a);
                setTableLoading(false);
                setPagination(pagination);
                setPage(pagination.current);
            }
        } catch (error) {
            setIsLoading(true);
        }
    }

    const handleChange = (value) => {
        setSelectedFilter(value);
    };

    const handleFilterValue = async (name, value) => {
        let isDeleted = false;

        var filterValue = undefined;

        var scount = -2;

        if (name === 'scount') {
            scount = -1;
            if (value === 'online') {
                scount = 1;
            }

            filterValue = scount;
        }

        if (name === 'username' || name === 'id') {
            filterValue = value;
        }

        if (name === 'isDeleted') {
            if (value === 'deleted') {
                isDeleted = true;
            }
        }

        setName(name);
        setValue(filterValue);
        setIsDeleted(isDeleted);
        setQueriedPage(1);
        setPage(1);

        var filterQuery = '';

        if (name && value && name !== 'isDeleted') {
            filterQuery = `${name}=${value}`;
        }

        if (name === 'scount') {
            filterQuery = `${name}=${filterValue}`;
        }

        const token = localStorage.getItem('token');
        const options = {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        };

        const response = await fetch(`${ROOT_URL_BACKEND}/backoffice/profiles?limit=${limit}&isDeleted=${isDeleted}&${filterQuery}`, options);

        const data = await response.json();

        const pagination = {
            current: 1,
            pageSize: limit,
            total: data.count,
            position: ['bottomCenter'],
        };

        setAllData(data.data);
        setData(data.data);
        setCount(data.count);
        setTableLoading(false);
        setPagination(pagination);
    };

    const tableTitle = () => {
        let input = null;
        if (selectedFilter === 'username') {
            input = <Search placeholder="input search text" onSearch={(value) => handleFilterValue('username', value)} style={{ width: 200 }} />;
        }

        if (selectedFilter === 'id') {
            input = (
                <Search
                    placeholder="input search text"
                    onSearch={(value, e) => {
                        handleFilterValue('id', value);
                    }}
                    style={{ width: 200 }}
                />
            );
        }

        if (selectedFilter === 'scount') {
            input = (
                <Select defaultValue="" style={{ width: 150 }} onChange={(value) => handleFilterValue('scount', value)}>
                    <Option value="online">online</Option>
                    <Option value="offline">offline</Option>
                </Select>
            );
        }

        if (selectedFilter === 'isDeleted') {
            input = (
                <Select defaultValue="" style={{ width: 150 }} onChange={(value) => handleFilterValue('isDeleted', value)}>
                    <Option value="deleted">Deleted</Option>
                    <Option value="notDeleted">Not Deleted</Option>
                </Select>
            );
        }

        return (
            <Space direction="vertical">
                <Space direction="vertical">
                    <h1>Users ({count})</h1>
                    <Text type="secondary">View, edit, and create users in your user pool. Users that are enabled and confirmed can sign in to your user pool.</Text>
                </Space>
                <Space direction="horizontal">
                    <Select defaultValue="id" style={{ width: 150 }} onChange={handleChange}>
                        <Option value="id">ID</Option>
                        <Option value="username">Username</Option>
                        <Option value="scount">Online Status</Option>
                        <Option value="isDeleted">Deleted</Option>
                    </Select>
                    {input}
                </Space>
            </Space>
        );
    };

    return (
        <MainLayout>
            {/* <PageHeader className="site-page-header" onBack={() => null} title="User List" /> */}
            <div
                className="site-layout-background"
                style={{
                    padding: 24,
                    minHeight: 360,
                }}
            >
                <Table columns={columns} dataSource={data} bordered loading={tableLoading} pagination={pagination} onChange={(v) => handleTableChange(v)} title={() => tableTitle()} />
            </div>
        </MainLayout>
    );
};
export default UserList;
