import React, { useState, useEffect } from 'react';
import { Descriptions, Typography, Skeleton, Card, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import moment from 'moment';

import { ROOT_URL_BACKEND } from 'src/api/constants';
import { MainLayout } from 'src/components/composite';

const { Text } = Typography;

const AdminInfo = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        async function getAdmin() {
            const token = localStorage.getItem('token');

            const options = {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            };

            const response = await fetch(`${ROOT_URL_BACKEND}/backoffice/me`, options);
            const data = await response.json();

            setData(data.data);
        }

        getAdmin();
    }, []);

    function renderRoles() {
        return data.roles.toString();
    }

    return (
        <MainLayout>
            {data ? (
                <>
                    <div
                        className="site-layout-background"
                        style={{
                            backgroundColor: 'white',
                            padding: 24,
                            minHeight: 360,
                        }}
                    >
                        <Card title="Admin Information">
                            <Descriptions>
                                <Descriptions.Item label="ID">{data._id}</Descriptions.Item>
                                <Descriptions.Item label="Username">{data.username}</Descriptions.Item>
                                <Descriptions.Item label="Email">{data.email}</Descriptions.Item>
                                <Descriptions.Item label="Roles" span={3}>
                                    {renderRoles()}
                                </Descriptions.Item>
                                <Descriptions.Item label="Last Login" span={3}>
                                    {moment(new Date(data.last_login), 'YYYYMMDD').fromNow()}
                                </Descriptions.Item>
                                <Descriptions.Item label="API Key Last Used" span={3}>
                                    {moment(new Date(data.keyUseAt), 'YYYYMMDD').fromNow()}
                                </Descriptions.Item>
                                <Descriptions.Item label="API Key">
                                    {' '}
                                    <Text code>{data.apikey}</Text>
                                    <Tooltip title="Copy">
                                        <CopyOutlined
                                            onClick={() => {
                                                navigator.clipboard.writeText(data.apikey);
                                            }}
                                            className="hover"
                                            style={{}}
                                        />
                                    </Tooltip>
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>

                        <br></br>
                    </div>
                </>
            ) : (
                <Skeleton title avatar paragraph={{ rows: 10 }} />
            )}
        </MainLayout>
    );
};

export default AdminInfo;
