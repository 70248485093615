import React, { useState } from 'react';
import { Row, Col, Image, Divider } from 'antd';
import { MessageList } from 'react-chat-elements';
import { ROOT_URL_BACKEND } from 'src/api/constants';
import { ChatList } from 'react-chat-elements';

const ConversationList = (props) => {
    const messageListReferance = React.createRef();

    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState([]);

    const getConversationMessage = (id) => {
        if (messages[id] === undefined) {
            const token = localStorage.getItem('token');
            const options = {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            };

            fetch(`${ROOT_URL_BACKEND}/backoffice/conversations/${id}/messages?limit=250`, options)
                .then((res) => res.json())
                .then((res) => {
                    const newMessages = JSON.parse(JSON.stringify(messages));
                    newMessages[id] = res.data;

                    const conversation = props.data.conversations.find((conversation) => {
                        return conversation._id === id;
                    });

                    const formattedNewmessages = [];

                    newMessages[id].forEach((message) => {
                        const partner = conversation.users.find((user) => user._id === message.sender);

                        const position = message.sender === props.data.profileId ? 'left' : 'right';
                        const type = message.type === 'TEXT' ? 'text' : 'photo';
                        var data = undefined;

                        if (type === 'photo') {
                            data = { uri: message.content };
                        }

                        const text = type !== 'photo' ? message.content : '';
                        const date = message.createdAt;
                        const status = message.sender !== props.data.profileId ? (message.isSeen.find((id) => id === props.data.profileId) ? 'read' : 'sent') : '';
                        const avatar = partner.profileImage;

                        formattedNewmessages.push({ position, type, text, date, status, avatar, data });
                    });

                    setMessages(formattedNewmessages);
                    setMessage(res.data);
                });
        } else {
            setMessage(messages[id]);
        }
    };
    return (
        <Row>
            <Col style={{ padding: '5px' }} flex={'100px'}>
                <div
                    style={{
                        height: 500,
                        overflow: 'auto',
                        width: 500,
                    }}
                >
                    <ChatList
                        className="chat-list"
                        onClick={(a) => getConversationMessage(a._id)}
                        dataSource={props.data.conversations.map((conversation) => {
                            const partner = conversation.users.find((user) => user._id !== props.data.profileId);
                            const avatar = partner.profileImage;
                            const title = partner.username;
                            let subtitle = conversation.lastMessage.content;
                            const date = conversation.lastMessage.createdAt;
                            const unread = conversation.unSeenCount;

                            if (conversation.lastMessage.type === 'IMAGE') {
                                subtitle = <Image width={30} src={conversation.lastMessage.content} />;
                            }
                            return { _id: conversation._id, avatar, title, subtitle, date, unread };
                        })}
                    />
                </div>
            </Col>
            <Divider style={{ height: 500 }} type="vertical" />

            <Col flex={'auto'}>
                <div
                    style={{
                        height: 500,
                        overflow: 'auto',
                    }}
                >
                    <MessageList referance={messageListReferance} className="message-list" lockable={true} toBottomHeight={'100%'} dataSource={messages} />
                </div>
            </Col>
        </Row>
    );
};

export default ConversationList;
