import { Table, Skeleton, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { ROOT_URL_BACKEND } from 'src/api/constants';
import { MainLayout } from 'src/components/composite';
import ReactJson from 'react-json-view';

const columns = [
    {
        title: 'Method',
        dataIndex: 'method',
        key: 'method',
    },
    {
        title: 'Path',
        dataIndex: 'path',
        key: 'path',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (data) => {
            let color = 'red';
            if (data >= 200 && data <= 299) {
                color = 'green';
            }
            if (data >= 400 && data <= 499) {
                color = 'gold';
            }

            return <Tag color={color}>{data}</Tag>;
        },
    },
    {
        title: 'Latency',
        dataIndex: 'latency',
        key: 'latency',
    },
    {
        title: 'User',
        dataIndex: 'profile',
        key: 'profile',
    },
    {
        title: 'Requested At',
        dataIndex: 'createdAt',
        key: 'createdAt',
    },
];

const Logs = () => {
    const [data, setData] = useState(undefined);

    useEffect(() => {
        async function getData() {
            const token = localStorage.getItem('token');
            const options = {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            };

            const response = await fetch(`${ROOT_URL_BACKEND}/backoffice/logs`, options);

            const response_data = await response.json();

            const modifiedData = response_data.data.map((d) => {
                return { key: d._id, ...d };
            });
            setData(modifiedData);
        }

        getData();
    }, []);

    return (
        <MainLayout>
            {data ? (
                <div
                    className="site-layout-background"
                    style={{
                        backgroundColor: 'white',
                        padding: 24,
                        minHeight: 360,
                    }}
                >
                    <Table
                        pagination={{
                            current: 1,
                            pageSize: 50,
                            total: 15000,
                            position: ['bottomCenter'],
                        }}
                        columns={columns}
                        expandable={{
                            expandedRowRender: (record) => (
                                <div>
                                    <div
                                        style={{
                                            margin: 0,
                                        }}
                                    >
                                        <h1>Headers</h1>

                                        <ReactJson theme={'hopscotch'} collapsed src={record.headers} />
                                    </div>
                                    {record.reqBody ? (
                                        <div
                                            style={{
                                                margin: 0,
                                            }}
                                        >
                                            <h1>Request</h1>

                                            <ReactJson theme={'hopscotch'} collapsed src={JSON.parse(record.reqBody)} />
                                        </div>
                                    ) : null}
                                    <div
                                        style={{
                                            margin: 0,
                                        }}
                                    >
                                        <h1>Response</h1>

                                        <ReactJson theme={'hopscotch'} collapsed src={JSON.parse(record.resBody)} />
                                    </div>
                                </div>
                            ),
                        }}
                        dataSource={data}
                    />
                </div>
            ) : (
                <Skeleton title avatar paragraph={{ rows: 50 }} />
            )}
        </MainLayout>
    );
};

export default Logs;
