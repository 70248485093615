import { Navigate } from "react-router-dom";


export const ProtectedRoute = ({ children }) => {
  

  const item = localStorage.getItem("token");

  if (!item) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};