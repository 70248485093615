import React from 'react';
import {
    Descriptions,
    // PageHeader,
    Badge,
    Typography,
    Tooltip,
    Modal,
    Skeleton,
    Avatar,
    message,
    Card,
} from 'antd';

import { CheckOutlined, CloseOutlined, CopyOutlined } from '@ant-design/icons';
import { MainLayout } from 'src/components/composite';
import { ConversationList } from 'src/components/ui';
import { ROOT_URL_BACKEND } from 'src/api/constants';
import moment from 'moment';

const { Text } = Typography;

export class UserDetail extends React.Component {
    constructor() {
        super();
        this.state = { data: null, isModalOpen: false, conversations: [] };
    }

    async componentDidMount() {
        const id = window.location.href.split('/')[4];

        const token = localStorage.getItem('token');
        const options = {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        };

        const response = await fetch(`${ROOT_URL_BACKEND}/backoffice/profiles/${id}`, options);
        const data = await response.json();

        await this.getConversationList();
        this.setState({ data: data.data });
    }

    getConversationList = async () => {
        const id = window.location.href.split('/')[4];

        const token = localStorage.getItem('token');

        const options = {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        };

        const response = await fetch(`${ROOT_URL_BACKEND}/backoffice/profiles/${id}/conversations`, options);
        const data = await response.json();

        this.setState({ conversations: data.totalCount > 0 ? data.data : [] });
    };

    showModal = () => {
        this.setState({ isModalOpen: true });
    };

    handleOk = () => {
        this.setState({ isModalOpen: false });
    };

    handleCancel = () => {
        this.setState({ isModalOpen: false });
    };

    renderTokenInside = (token) => {
        const keys = Object.keys(token);
        keys.splice(keys.indexOf('profileId'), 1);
        keys.splice(keys.indexOf('device'), 1);
        return keys.map((key) => {
            if (key === 'groupMessage' || key === 'singleMessage') {
                return (
                    <>
                        <Text strong>{key}:</Text>
                        <Text code>{token[key] ? <CheckOutlined /> : <CloseOutlined />}</Text>
                        <br />
                    </>
                );
            } else {
                return (
                    <>
                        <Text strong>{key}:</Text>
                        <Text code>{token[key]}</Text>
                        {key === 'token' ? (
                            <Tooltip title="Copy">
                                {' '}
                                <CopyOutlined
                                    onClick={() => {
                                        navigator.clipboard.writeText(token[key]);
                                    }}
                                    className="hover"
                                    style={{}}
                                />
                            </Tooltip>
                        ) : null}
                        <br />
                    </>
                );
            }
        });
    };

    renderTokenPart = () => {
        const { tokens } = this.state.data;

        return tokens?.map((token) => {
            return (
                <Descriptions.Item
                    span={3}
                    label={
                        <>
                            <Text strong>Device:</Text> <Text code>{token.device}</Text>
                        </>
                    }
                >
                    {this.renderTokenInside(token)}
                </Descriptions.Item>
            );
        });
    };

    renderSocketInside = (token) => {
        let socket = { error: token.error };

        if (!token.error) {
            socket = {
                SourceIp: token.info.Identity.SourceIp,
                UserAgent: token.info.Identity.UserAgent,
                LastActiveAt: token.info.LastActiveAt,
                ConnectedAt: token.info.ConnectedAt,
                status: token.status,
            };
        }

        const keys = Object.keys(socket);

        return keys.map((key) => {
            if (token.error) {
                return (
                    <>
                        <Text strong>Error: </Text>
                        <Text code>Gone Exception</Text>
                        <br />
                    </>
                );
            } else {
                return (
                    <>
                        <Text strong>{key}:</Text>
                        <Text code>{socket[key]}</Text>
                        <br />
                    </>
                );
            }
        });
    };

    renderSocketPart = () => {
        const { sockets } = this.state.data;

        return sockets?.map((socket) => {
            return (
                <Descriptions.Item
                    span={3}
                    label={
                        <>
                            <Text strong>ConnectionId:</Text> <Text code>{socket.connectionId}</Text>
                        </>
                    }
                >
                    {this.renderSocketInside(socket)}
                </Descriptions.Item>
            );
        });
    };

    getWsKey = async () => {
        const id = window.location.href.split('/')[4];
        const token = localStorage.getItem('token');
        const options = {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        };
        const response = await fetch(`${ROOT_URL_BACKEND}/backoffice/profiles/${id}/sockets`, options);
        const data = await response.json();

        this.setState({ wsToken: data.data, isModalOpen: true });
    };

    removeGoneSockets = async () => {
        const id = window.location.href.split('/')[4];
        const token = localStorage.getItem('token');
        const options = {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + token,
            },
        };

        let response = await fetch(`${ROOT_URL_BACKEND}/backoffice/profiles/${id}/sockets/checks`, options);
        let data = await response.json();

        if (response.status === 200) {
            message.success('successfully removed gone sockets');

            const id = window.location.href.split('/')[4];

            const options = {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            };

            response = await fetch(`${ROOT_URL_BACKEND}/backoffice/profiles/${id}`, options);
            data = await response.json();

            this.setState({ data: data.data });
        }
    };

    render() {
        const { data } = this.state;

        return (
            <MainLayout>
                {data ? (
                    <>
                        {/* <PageHeader
              className="site-page-header"
              onBack={() => (window.location.href = "/users")}
              title="User"
              subTitle={data.profile._id}
            /> */}

                        <div
                            className="site-layout-background"
                            style={{
                                backgroundColor: 'white',
                                padding: 24,
                                minHeight: 360,
                            }}
                        >
                            <Card title="General Profile Information" extra={<a onClick={this.getWsKey}>Get WS Key</a>}>
                                <Descriptions>
                                    <Descriptions.Item label="" span={3}>
                                        <Avatar size={64} src={data.profile.profileImage}>
                                            {data.profile.username[0].toUpperCase() + data.profile.username[1].toUpperCase()}
                                        </Avatar>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="ID">{data.profile._id}</Descriptions.Item>
                                    <Descriptions.Item label="Username">{data.profile.username}</Descriptions.Item>

                                    <Descriptions.Item label="Created At" span={2}>
                                        2019-04-24 18:00:00
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Status">
                                        <Badge status={data.status === 'online' ? 'success' : 'default'} text={data.status} />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Last Activiy">{data.profile.lastSeen ? moment(new Date(data.profile.lastSeen), 'YYYYMMDD').fromNow() : 'No Activity'}</Descriptions.Item>
                                </Descriptions>
                            </Card>
                            <Modal title="WS Key" open={this.state.isModalOpen} onOk={this.handleOk} onCancel={this.handleCancel}>
                                <Text strong>Device:</Text> <Text code>{this.state.wsToken}</Text>{' '}
                                <Tooltip title="Copy">
                                    {' '}
                                    <CopyOutlined
                                        onClick={() => {
                                            navigator.clipboard.writeText(this.state.wsToken);
                                        }}
                                        className="hover"
                                        style={{}}
                                    />
                                </Tooltip>
                            </Modal>
                            <br></br>
                            <Card title="Notification Token Information">
                                <Descriptions bordered>
                                    <Descriptions.Item label="Total Notification Token" span={3}>
                                        {data.tokens ? data.tokens.length : 0}
                                    </Descriptions.Item>
                                    {this.renderTokenPart()}
                                </Descriptions>
                                <br></br>
                            </Card>
                            <br></br>
                            <Card title="Web Socket Information" extra={<a onClick={this.removeGoneSockets}>Remove Gone Sockets</a>}>
                                <Descriptions bordered>
                                    <Descriptions.Item span={3} label="Total Socket">
                                        {data.sockets ? data.sockets.length : 0}
                                    </Descriptions.Item>
                                    {this.renderSocketPart()}
                                </Descriptions>
                            </Card>
                            <br></br>
                            <h1 style={{ fontWeight: 'bold' }}>Conversations</h1>
                            <ConversationList
                                data={{
                                    profileId: this.state.data.profile._id,
                                    conversations: this.state.conversations,
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <Skeleton title avatar paragraph={{ rows: 10 }} />
                )}
            </MainLayout>
        );
    }
}

export default UserDetail;
