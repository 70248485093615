import React, { useState, useEffect } from 'react';
import { Descriptions, Typography, Skeleton, Card } from 'antd';

import { ROOT_URL_BACKEND } from 'src/api/constants';
import { MainLayout } from 'src/components/composite';

const { Text } = Typography;

const ApplicationInfo = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        async function getAdmin() {
            const token = localStorage.getItem('token');

            const options = {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            };

            const response = await fetch(`${ROOT_URL_BACKEND}/backoffice/server`, options);
            const data = await response.json();

            setData(data.data);
        }

        getAdmin();
    }, []);

    return (
        <MainLayout>
            {data ? (
                <>
                    <div
                        className="site-layout-background"
                        style={{
                            backgroundColor: 'white',
                            padding: 24,
                            minHeight: 360,
                        }}
                    >
                        <Card title="Application Variables">
                            <Descriptions>
                                <Descriptions.Item span={3} label={<span style={{ fontWeight: 'bold' }}>Client Key</span>}>
                                    <Text code>{data.KEY}</Text>
                                </Descriptions.Item>
                                <Descriptions.Item span={3} label={<span style={{ fontWeight: 'bold' }}>Admin Key</span>}>
                                    <Text code>{data.ADMIN_KEY}</Text>
                                </Descriptions.Item>
                                <Descriptions.Item span={3} label={<span style={{ fontWeight: 'bold' }}>Port</span>}>
                                    <Text code>{data.PORT}</Text>
                                </Descriptions.Item>
                                <Descriptions.Item span={3} label={<span style={{ fontWeight: 'bold' }}>PID</span>}>
                                    <Text code>{data.PID}</Text>
                                </Descriptions.Item>
                                <Descriptions.Item span={3} label={<span style={{ fontWeight: 'bold' }}>Cloudfront URL</span>}>
                                    <Text code>{data.AWS_CLOUDFRONT_URL}</Text>
                                </Descriptions.Item>
                                <Descriptions.Item span={3} label={<span style={{ fontWeight: 'bold' }}>Database URL</span>}>
                                    <Text code>{data.DB_URL.split('?')[0]}</Text>
                                </Descriptions.Item>
                                <Descriptions.Item span={3} label={<span style={{ fontWeight: 'bold' }}>Database User</span>}>
                                    <Text code>{data.DB_USER}</Text>
                                </Descriptions.Item>
                                <Descriptions.Item span={3} label={<span style={{ fontWeight: 'bold' }}>Database Name</span>}>
                                    <Text code>{data.DB_NAME}</Text>
                                </Descriptions.Item>
                                <Descriptions.Item span={3} label={<span style={{ fontWeight: 'bold' }}>Region</span>}>
                                    <Text code>{data.AWS_REGION}</Text>
                                </Descriptions.Item>
                                <Descriptions.Item span={3} label={<span style={{ fontWeight: 'bold' }}>Access Key</span>}>
                                    <Text code>{data.AWS_ACCESS_KEY}</Text>
                                </Descriptions.Item>
                                <Descriptions.Item span={3} label={<span style={{ fontWeight: 'bold' }}>Web Socket API Gateway</span>}>
                                    <Text code>{data.AWS_WS_API_ID}</Text>
                                </Descriptions.Item>
                                <Descriptions.Item span={3} label={<span style={{ fontWeight: 'bold' }}>Bucket Name</span>}>
                                    <Text code>{data.AWS_BUCKET_NAME}</Text>
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>

                        <br></br>
                    </div>
                </>
            ) : (
                <Skeleton title avatar paragraph={{ rows: 10 }} />
            )}
        </MainLayout>
    );
};

export default ApplicationInfo;
