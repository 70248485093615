import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { ROOT_URL_BACKEND } from 'src/api/constants';
import { Descriptions, Card, Skeleton, List } from 'antd';
import { MainLayout } from 'src/components/composite';

const Socket = () => {
    const [data, setData] = useState(undefined);

    useEffect(() => {
        async function getData() {
            const token = localStorage.getItem('token');
            const options = {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            };

            const response = await fetch(`${ROOT_URL_BACKEND}/backoffice/ws-infra`, options);

            const response_data = await response.json();

            setData(response_data.data);
        }

        getData();
    }, []);

    return (
        <MainLayout>
            {data ? (
                <div
                    className="site-layout-background"
                    style={{
                        backgroundColor: 'white',
                        padding: 24,
                        minHeight: 360,
                    }}
                >
                    <Card title="Web Socket Infrastruture">
                        <Descriptions title="API">
                            <Descriptions.Item label="ID">{data.api[1].Value}</Descriptions.Item>
                            <Descriptions.Item label="Name">{data.api[5].Value}</Descriptions.Item>
                            <Descriptions.Item label="Protocol">{data.api[6].Value}</Descriptions.Item>
                            <Descriptions.Item label="End Point">{data.api[0].Value}</Descriptions.Item>
                            <Descriptions.Item label="Created At">{data.api[3].Value}</Descriptions.Item>
                            <Descriptions.Item label="Restart At">{moment(new Date(data.restartAt), 'YYYYMMDD').fromNow()}</Descriptions.Item>
                        </Descriptions>
                        <br />
                        <Descriptions title="Authorizer">
                            <Descriptions.Item label="ID">{data.authorizer[0].Value}</Descriptions.Item>
                            <Descriptions.Item label="Name">{data.authorizer[1].Value}</Descriptions.Item>
                            <Descriptions.Item label="URI">{data.authorizer[2].Value}</Descriptions.Item>
                            <Descriptions.Item label="End Point">{data.authorizer[3].Value}</Descriptions.Item>
                            <Descriptions.Item label="Created At">{data.authorizer[4].Value}</Descriptions.Item>
                        </Descriptions>
                        <br />
                        <Descriptions title="Authorizer Function">
                            <Descriptions.Item label="ARN">{data.authorizer[5].Value[0].Value}</Descriptions.Item>
                            <Descriptions.Item label="Name">{data.authorizer[5].Value[1].Value}</Descriptions.Item>
                            <Descriptions.Item label="Last Modified">{data.authorizer[5].Value[2].Value}</Descriptions.Item>
                            <Descriptions.Item label="Handler">{data.authorizer[5].Value[3].Value}</Descriptions.Item>
                            <Descriptions.Item label="Run Time">{data.authorizer[5].Value[4].Value}</Descriptions.Item>
                            <Descriptions.Item label="State">{data.authorizer[5].Value[5].Value}</Descriptions.Item>
                            <Descriptions.Item label="Key">{data.clientKey}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                    <br />
                    <Card title="Routes">
                        <List
                            itemLayout="horizontal"
                            dataSource={data.routes}
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta title={item[0].Value} description={item[1].Value} />
                                </List.Item>
                            )}
                        />
                    </Card>
                </div>
            ) : (
                <Skeleton title avatar paragraph={{ rows: 10 }} />
            )}
        </MainLayout>
    );
};

export default Socket;
