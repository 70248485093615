import { DesktopOutlined, TeamOutlined, HddOutlined, AmazonOutlined,UnorderedListOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AdminInfo from './AdminInfo';
const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}

const items = [
    getItem('Dashboard', '/', <DesktopOutlined />),
    getItem('Users', '/users', <TeamOutlined />),
    getItem('Application', '/applications', <HddOutlined />),
    getItem('Web Socket', '/websocket', <AmazonOutlined />),
    getItem('Logs', '/logs', <UnorderedListOutlined />),
];

const MainLayout = (props) => {
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();

    const onClick = (e) => {
        navigate(e.key);
    };

    const [selectedKeys, setSelectedKeys] = useState([window.location.pathname]);

    useEffect(() => {
        setSelectedKeys([window.location.pathname]);
    }, []);

    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <Menu theme="dark" defaultSelectedKeys={selectedKeys} mode="inline" items={items} onClick={onClick} />
            </Sider>
            <Layout>
                <Header>
                    <div style={{ float: 'right' }}>
                        <AdminInfo />
                    </div>
                </Header>
                <Content>{props.children}</Content>
                <Footer style={{ textAlign: 'center' }}>Chat Service ©2022 Created by ayck 🐻</Footer>
            </Layout>
        </Layout>
    );
};
export default MainLayout;
