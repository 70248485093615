import { Col, Statistic } from 'antd';
import React, { useState, useEffect } from 'react';
import { ROOT_URL_BACKEND } from 'src/api/constants';

const Stats = () => {
    const [dailyUsers, setDailyUsers] = useState(0);
    const [messagesCount, setMessagesCount] = useState(0);
    const [conversationCount, setConversationCount] = useState(0);

    useEffect(() => {
        async function getData() {
            const token = localStorage.getItem('token');
            const options = {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            };

            const response_activeProfile = await fetch(`${ROOT_URL_BACKEND}/backoffice/profiles/active`, options);

            const response_data = await response_activeProfile.json();

            setDailyUsers(response_data.data);

            const response_conversationCount = await fetch(`${ROOT_URL_BACKEND}/backoffice/conversations/count`, options);

            const response_data2 = await response_conversationCount.json();

            setMessagesCount(response_data2.data);

            const response_messageCount = await fetch(`${ROOT_URL_BACKEND}/backoffice/conversations/messages/count`, options);

            const response_data3 = await response_messageCount.json();

            setConversationCount(response_data3.data);
        }

        getData();
    }, []);

    return (
        <>
            <Col span={8}>
                <Statistic title="Users Become Online Today" value={dailyUsers} />
            </Col>
            <Col span={8}>
                <Statistic title="Total Conversation" value={conversationCount} />
            </Col>
            <Col span={8}>
                <Statistic title="Total Messages" value={messagesCount} />
            </Col>
        </>
    );
};

export default Stats;
