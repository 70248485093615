import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Dashboard, Login, UserDetail, UserList } from 'src/pages';
import AdminInfo from './pages/AdminInfo';
import ApplicationInfo from './pages/ApplicationInfo';
import { ProtectedRoute } from './ProtectedRoute';
import Socket from './pages/Socket';
import Logs from './pages/Logs';

const App = () => {
    return (
        <>
            <Routes>
                <Route
                    path="/"
                    element={
                        <ProtectedRoute>
                            <Dashboard />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/"
                    element={
                        <ProtectedRoute>
                            <Dashboard />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/admins"
                    element={
                        <ProtectedRoute>
                            <AdminInfo />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/applications"
                    element={
                        <ProtectedRoute>
                            <ApplicationInfo />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/users"
                    element={
                        <ProtectedRoute>
                            <UserList />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/users/:id"
                    element={
                        <ProtectedRoute>
                            <UserDetail />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/websocket"
                    element={
                        <ProtectedRoute>
                            <Socket />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/logs"
                    element={
                        <ProtectedRoute>
                            <Logs />
                        </ProtectedRoute>
                    }
                />

                <Route path="/login" element={<Login />} />
            </Routes>
        </>
    );
};

export default App;
